<template>
  <v-container fluid>
    <v-row>
      <v-col class="ml-2" lg="2" sm="3">
        <v-btn class="back-button" elevation="0" text @click="goToMarketing">
          <SvgIcon text="Back">
            <template v-slot:icon>
              <BackIcon/>
            </template>
          </SvgIcon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" lg="2" sm="4" style="float: right">
        <v-btn
            v-if="checkWritePermission($modules.marketing[type].slug)"
            class="mr-6 bg-blue text-white"
            dark
            height="45"
            @click="gotoAddForm"
        >
          <v-icon dark left>mdi-plus-circle</v-icon>
          Add
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="notifications.length>0">
      <v-row class="mt-4">
        <v-col v-for="notification in notifications" :key="notification.id" lg="4" md="6" sm="12" xl="3">
          <v-card class="shadow pointer" style="border-radius: 8px" @click="showNotification(notification)">
            <v-card-text>
              <p class="font-semibold text-lg black--text line-clamp-2 mt-6 blog-title">{{ notification.title }}</p>
              <div>
                <p class="text-sm text-blue line-clamp-3 mb-1">
                  Start Date: {{ moment(notification.start_date).format("ddd, DD MMM YYYY") }}
                </p>
                <p class="text-sm text-blue line-clamp-3 mb-1">
                  End Date: {{ moment(notification.end_date).format("ddd, DD MMM YYYY") }}
                </p>
                <p class="text-sm text-blue mb-1 d-flex align-center gap-x-2">
                  Status:
                  <LightningBoltIcon :class="notification.is_public ? '':'fill-red'"/>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br>
      <v-pagination
          v-if="totalPages > 0"
          v-model="page"
          :length="totalPages"
      ></v-pagination>
    </template>
    <p class="text-2xl text-blue font-semibold text-center py-16" v-else>
      No Notifications Found
    </p>
  </v-container>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import BackIcon from "@/assets/images/misc/back-icon.svg";
import moment from "moment";
import LightningBoltIcon from "@/assets/images/facilities/lightning-bolt.svg";

export default {
  computed: {
    moment() {
      return moment
    }
  },
  components: {
    LightningBoltIcon,
    BackIcon,
    SvgIcon,
  },
  mounted() {
    if (typeof this.$route.params.type != "undefined") {
      this.type = this.$route.params.type;
    }
    this.getData();
  },
  data() {
    return {
      type: "website_notification",
      notifications: [],
      page: 1,
      totalPages: 1,
      id: null,
      search: "",
    };
  },
  methods: {
    goToMarketing() {
      this.$router.push({name: "Marketing"}, () => {
      });
    },
    gotoAddForm() {
      this.$router.push({
        name: "AddWebsiteNotification",
      });
    },
    getData() {
      this.$http
          .get("venues/marketing/website-notifications", {
            params: {
              page: this.page,
            }
          })
          .then((response) => {
            if (response.status == 200) {
              this.notifications = response.data.data.data;
              this.page = response.data?.data?.current_page || 1;
              this.totalPages = response.data?.data?.last_page || 0;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    showNotification(notification = null) {
      if (notification) {
        this.$router.push({name: "AddWebsiteNotification", params: {id: btoa(notification.id)}});
      } else {
        this.$router.push({name: "AddWebsiteNotification"});
      }
    },
  },
};
</script>

<style scoped>


.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fill-red {
  fill: red !important;
}
</style>